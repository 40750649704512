import React from 'react'
import './App.scss'
import Login from './pages/Login/Login'
import Live from './pages/Live/Live'
import { auth } from './state/FirebaseApp'
import Logo from './logo.png'
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase/app'
import './Header.scss'
import ProgressBar from './components/ProgressBar/ProgressBar'
import ReplayMap from './pages/ReplayMap/ReplayMap'
import Admin from './pages/Admin/Admin'
import { initialRootState, RootContext, rootReducer, SELECT_MAP } from './state/RootContext'
import useMapName from './hooks/useMapName'
import ErrorBox from './components/ErrorBox'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserManagement from './components/UserManagement/UserManagement'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useMapTemplateInfo } from './hooks/useCurrentMap'
import Settings from './pages/Settings/Settings'
import ForgotPassword from './pages/ForgotPassword'
import PaperContainer from './components/PaperContainer/PaperContainer'
import { Button, Box } from '@mui/material'
const queryClient = new QueryClient()

const versionCheck = () => {
  const expectedVersion = '1.2.0'
  const version = localStorage.getItem('version')

  if (expectedVersion !== version) {
    localStorage.setItem('version', expectedVersion)
    window.location.reload()
  }
}

versionCheck()

const useIsAnySiteOwner = (user: firebase.User | undefined) => {
  const [token, setToken] = React.useState<firebase.auth.IdTokenResult>()
  
  React.useEffect(() => {
    if (user) {
      user
        .getIdTokenResult(true)
        .then(setToken)
    }
  }, [user])

  return token?.claims?.roles?.find((it: string) => it.includes(':owner') !== undefined) ?? false
}


const useSelectedMapOwner = (user: firebase.User | undefined, selectedMap: string | undefined) => {
  const [token, setToken] = React.useState<firebase.auth.IdTokenResult>()
  
  React.useEffect(() => {
    if (user) {
      user
        .getIdTokenResult(true)
        .then(setToken)
    }
  }, [user])

  if (selectedMap === undefined) {
    return false
  }

  return token?.claims?.roles?.find((it: string) => it === `${selectedMap}:owner`) ?? false
}

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth) as [firebase.User | undefined, boolean, Error|undefined]
  const isAnySiteOwner = useIsAnySiteOwner(user)
  const { state, dispatch } = React.useContext(RootContext)
  const { selectedMap } = state
  const isSelectedMapOwner = useSelectedMapOwner(user, selectedMap)
  const { maps: mapNames, fetching: fetchingMaps, error: errorMaps } = useMapName(user)
  const { info } = useMapTemplateInfo(selectedMap)
  const history = useHistory()
  const [signingOut, setSigningOut] = React.useState(false)

  React.useEffect(() => {
    if (mapNames && !selectedMap) {
      dispatch({ type: SELECT_MAP, map: mapNames[0] })
    }
  }, [mapNames, selectedMap, dispatch])

  const onSignOut = React.useCallback(async () => {
    setSigningOut(true)
    localStorage.removeItem('RootContext-selectedMap')
    await auth.signOut()
    history.push('/login')
    setSigningOut(false)
  }, [history])

  const onChangeMap = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: SELECT_MAP, map: e.target.value })
  }, [dispatch])

  if (loading) {
    return <ProgressBar />
  }

  if (fetchingMaps) {
    return <ProgressBar />
  }

  if (errorMaps) {
    return (
      <PaperContainer>
        <ErrorBox error={errorMaps} />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={signingOut}
              onClick={onSignOut}
            >
              {signingOut ? 'Signing out...' : 'Sign out'}
            </Button>
        </Box>
      </PaperContainer>
    )
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/">
          <Login />
        </Route>
      </Switch>
    )
  }

  const isAdminFeaturesDeveloped = false
  
  return (
    <div className="App">
      <div className="SideMenu">
        <img src={Logo} width="150" height="22" alt="Logyard" />
 
        {mapNames && mapNames.length > 1 && (
          <select onChange={onChangeMap} value={selectedMap}>
            {mapNames.map(name => <option key={name}>{info?.uid === name ? info.title : name}</option>)}
          </select>
        )}

        <NavLink className="button live" to="/" exact>Live</NavLink>
        {isSelectedMapOwner && <NavLink className="button replay" to="/replay">Replay</NavLink>}
        {isAdminFeaturesDeveloped && isAnySiteOwner && <NavLink className="button admin" to="/admin">Admin</NavLink>}
          {user.email === 'colin.capurso@enabled.com.au' && (
            <NavLink className="button user-management" to="/user-management" exact>User Management</NavLink>
          )}
        <NavLink className="button settings" to="/settings">Settings</NavLink>
        <button className="button logout" onClick={onSignOut}>Logout</button>
      </div>

      <div className="Content">
        <Switch>
          {isAdminFeaturesDeveloped && isAnySiteOwner && (
            <Route path="/admin">
              <Admin />
            </Route>
          )}

          {user.email === 'colin.capurso@enabled.com.au' && (
            <Route path="/user-management">
              <UserManagement />
            </Route>
          )}

          {isSelectedMapOwner && (
            <Route path="/replay">
              <ReplayMap />
            </Route>
          )}

          <Route path="/settings">
            <Settings />
          </Route>

          <Route path="/">
            <Live />
          </Route>
        </Switch>
      </div>
      <ToastContainer position="bottom-right" theme="dark"/>
    </div>
  )
}

const AppContainer = () => {
  const [state, dispatch] = React.useReducer(rootReducer, initialRootState)

  return (
    <RootContext.Provider value={{ state, dispatch }}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </RootContext.Provider>
  )
}

export default AppContainer
