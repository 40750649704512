import React from 'react'

const NEW_SESSION = 'NEW_SESSION'
export const SELECT_MAP = 'SELECT_MAP'

export type RootState = {
  selectedMap?: string
}

export const initialRootState: RootState = {
  selectedMap: localStorage.getItem('RootContext-selectedMap') || undefined,
}

export type RootActions = { type: typeof NEW_SESSION }
  | { type: typeof SELECT_MAP, map: string|undefined }

export const RootContext = React.createContext<{ state: RootState, dispatch: (action: RootActions) => void }>({
  state: initialRootState,
  dispatch: () => { return },
})

export const rootReducer = (state: RootState, action: RootActions): RootState => {
  if (process.env.NODE_ENV === 'development') {
    console.log(action)
  }

  switch (action.type) {
    case SELECT_MAP:
      if (action.map) {
        localStorage.setItem('RootContext-selectedMap', action.map)
      } else {
        localStorage.removeItem('RootContext-selectedMap')
      }
      
      return {
        ...state,
        selectedMap: action.map,
      }
  }

  return {...state}
}