import React from 'react'
import { LogyardMap, Bay, BayState } from '../../types'
import * as d3 from 'd3'
import { MapInfo } from '../../hooks/useMaps'
import { storage } from '../../state/FirebaseApp'
import ErrorBox from '../ErrorBox'
import useGrabberLocations from '../../hooks/useGrabberLocations'
import { makeTransformer } from '../../utils/calculateHomography'
import './MapView.scss'

const getMapSize = (map: LogyardMap): [number, number] => map.size

type Rect = {
  x: number,
  y: number,
  width: number,
  height: number,
  angle: number,
  state: BayState,
  pattern: string
}

const toRect = (bay: Bay): Rect => {
  const numbers: [
    [number, number],
    [number, number],
  ] = JSON.parse(bay.rect.replace(/{/g, '[').replace(/}/g, ']'))

  return {
    x: numbers[0][0],
    y: numbers[0][1],
    width: numbers[1][0],
    height: numbers[1][1],
    angle: bay.angle,
    state: bay.state,
    pattern: bay.pattern
  }
}

type MapViewProps = {
  map: LogyardMap
  mapInfo: MapInfo
  showGrabbers?: boolean
}

const MapView: React.FC<MapViewProps> = (props) => {
  const { map, mapInfo, showGrabbers } = props
  const d3Container = React.useRef(null)
  const [width, height] = getMapSize(map)
  const [, setMouse] = React.useState<[number, number]>([0, 0])
  const [backgroundImage, setBackgroundImage] = React.useState<string>()
  const [backgroundImageError, setBackgroundImageError] = React.useState<Error>()
  const {
    grabbers: grabberLocationsRaw,
  } = useGrabberLocations(showGrabbers ? mapInfo.uid : undefined)

  const transformer = React.useMemo(() => {
    if (mapInfo.geoBounds) {
      return {
        homography: makeTransformer(
          mapInfo.geoBounds.topLeft,
          mapInfo.geoBounds.topRight,
          mapInfo.geoBounds.bottomRight,
          mapInfo.geoBounds.bottomLeft,
          [0,0],
          [map.size[0], 0],
          [map.size[0], map.size[1]],
          [0, map.size[1]]
        )
      }
    }

    return null
  }, [map, mapInfo])

  const transformGps = React.useCallback((_lat: number, _lng: number) => {
    if (transformer) {
      return transformer.homography(_lat, _lng)
    }
    
  }, [transformer])

  const grabberLocations = React.useMemo(() => {
    return grabberLocationsRaw
      .map(it => ({
        ...it,
        point: transformGps(it.coordinate[0], it.coordinate[1])
      }))
      .sort((a, b) => a.email.localeCompare(b.email))
  }, [transformGps, grabberLocationsRaw])

  React.useEffect(() => {
    const load = async () => {
      if (mapInfo) {
        try {
          setBackgroundImageError(undefined)
          setBackgroundImage(await storage.refFromURL(mapInfo.image_png_light).getDownloadURL())
        } catch (err: any) {
          setBackgroundImageError(err)
        }
      }
    }
    
    load()
  }, [mapInfo])

  React.useEffect(() => {
    if (map && d3Container.current) {
      d3
        .select(d3Container.current)
        .append('div.mapRoot')
        .attr('class', 'mapRoot')
        .selectAll('div.bay')
        .remove()
        .data(map.regions[0].bays.filter(item => item.state !== 'Split').map(item => toRect(item)))
        .enter()
        .append('div')
        .attr('class', d => `bay ${d.state}`)
        .style('left', d => `${d.x}px`)
        .style('top', d => `${d.y}px`)
        .style('width', d => `${d.width}px`)
        .style('height', d => `${d.height}px`)
        .style('transform', d => `rotate(${d.angle}deg)`)
        .append('div')
        .attr('class', 'Text')
        .text(d => d.pattern)
        .exit()
        .remove()
    } else {
      d3
        .select(d3Container.current)
        .selectAll('div.mapRoot')
        .remove()
    }
  }, [map])

  const [lat, setLat] = React.useState(-37.840151)
  const [lng] = React.useState(140.804139)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLat(lat - 0.00001)
      // setLng(lng + 0.00001)
    }, 500)

    return () => clearTimeout(timer)
  }, [lat, lng])

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setMouse([e.nativeEvent.offsetX, e.nativeEvent.offsetY])
  }
  
  return (
    <div className="MapView">
      <ErrorBox error={backgroundImageError} />
      <div onMouseMove={onMouseMove} className="d3-component" ref={d3Container} style={{ width, height }}>
        {map && backgroundImage && <img src={backgroundImage} style={{ mixBlendMode: 'multiply' }} alt="sitemap" className="MapBackground" />}
        {grabberLocations
          .filter(it => it.point).map(it => <Grabber key={it.email} grabber={it} />)}
      </div>
    </div>
  )
}

type GrabberExtended = {
  point: {
      x: number;
      y: number;
  } | undefined;
  coordinate: [number, number];
  email: string;
  name: string;
}

type GrabberProps = {
  grabber: GrabberExtended
}
const Grabber: React.FC<GrabberProps> = (props) => {
  const { grabber } = props
  const top = ~~(grabber.point?.y ?? 0)
  const left = ~~(grabber.point?.x ?? 0)

  return (
    <div className="GrabberContainer" style={{ top, left }}>
      <div className="GrabberName">{grabber.name}</div>
      <div className="Grabber"/>
    </div>
  )
}

export default MapView