import React from "react"
import firebase from 'firebase/app'

const useMapName = (user?: firebase.User) => {
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const [maps, setMaps] = React.useState<string[]>()

  React.useEffect(() => {
    const load = async () => {
      try {
        setFetching(true)

        if (user) {
          const result = await user.getIdTokenResult(true)

          if (result.claims.roles) {
            const mapNames = result.claims.roles.map((it: string) => it.split(':')[0])
            setMaps(mapNames)
          } else {
            throw new Error('User does not have access to any maps')
          }
        }
      } catch (err: any) {
        setError(err)
      } finally {
        setFetching(false)
      }
    }

    load()
  }, [user])

  React.useEffect(() => {
    setError(undefined)
  }, [user])

  return {
    fetching,
    error,
    maps,
  }
}

export default useMapName