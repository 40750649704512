import React from 'react'
import './SliderControl.scss'

const SliderControl: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = (props) => {
  return (
    <div className="SliderControl">
      <input type="range" className="Control" {...props}/>
    </div>
  )
}

export default SliderControl