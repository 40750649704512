import { useQuery } from 'react-query'
import { db } from '../../state/FirebaseApp'

export type User = {
  id: string
  email: string
  name: string

  /**
   * @notes Current version of the app. This is only available in app version 1.3 and newer.
   */
  version?: string

  /**
   * @notes Time last signed into app. This is only available in app version 1.3 and newer.
   */
  deviceSignInTimeMs?: number 

  /**
   * @notes Temporary. This is for the purpose of testing.
   */
  admin?: boolean
}

const fetchUsers = async () => {
  const snapshot = await db.collection('users').get()
  const toReturn: User[] = []
  snapshot.forEach((doc) => {
    toReturn.push({ ...doc.data(), id: doc.id } as User)
  })
  return toReturn
}

export default function useUsers() {
  return useQuery('useUsers', fetchUsers)
}