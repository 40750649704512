import React from 'react'
import { LogyardHistoryMap } from '../../types'
import './HorizontalTimeline.scss'

type HorizontalTimelineProps = {
  minMs: number
  maxMs: number
  selectedMap: LogyardHistoryMap
  items: LogyardHistoryMap[]
  onClick: (it: LogyardHistoryMap) => void
}

const HorizontalTimeline: React.FC<HorizontalTimelineProps> = (props) => {
  const { minMs, maxMs, selectedMap, items, onClick } = props

  const getX = React.useCallback((val: number): string => {
    const a = val - minMs
    const b = maxMs - minMs
    
    return (a/b) * 100 + '%'
  }, [minMs, maxMs])

  return (
    <div className="HorizontalTimeline">
      {items.map(it => <span key={it.id} className={`Snapshot ${selectedMap.updated_at_ms === it.updated_at_ms ? 'Selected' : ''}`} style={{ left: getX(it.updated_at_ms) }} onClick={() => onClick(it)}/>)}
    </div>
  )
}

export default HorizontalTimeline