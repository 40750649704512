import dayjs from "dayjs"
import { toast } from "react-toastify"
import { LogyardHistoryMap, LogyardMap } from "../types"
import { diff as justDiff, Operation } from 'just-diff'

const isLogyardHistoryMap = (val: any): val is LogyardHistoryMap => typeof val.map_name === 'string'
const isLogyardMap = (val: any): val is LogyardMap => typeof val.map_name !== 'string'

const toastMessage = (prev: LogyardHistoryMap | LogyardMap, next: LogyardHistoryMap | LogyardMap) => {
  // For History view
  if (isLogyardHistoryMap(prev) && isLogyardHistoryMap(next)) {
    const diff = justDiff(prev.map, next.map)
    const updatedAt = next.updated_at_ms
    toastMessageFromDiff(updatedAt, diff)
  }

  // For Live view
  if (isLogyardMap(prev) && isLogyardMap(next)) {
    const updatedAt = Math.floor(next.updatedAt)*1000
    const diff = justDiff(prev, next)
    toastMessageFromDiff(updatedAt, diff)
  }
}

export const toastMessageFromDiff = (updatedAtMs: number, diff: {
  op: Operation
  path: (string | number)[]
  value: any
}[]) => {
  const text = diff
    .filter(it => {
      return it.path.includes('pattern') || it.path.includes('state')
    })
    .filter(it => it.path.includes('bays'))
    .filter(it => it.op === 'replace')
    .map(it => {
      const bayNumber = it.path[3]
      const type = it.path[4]
      const value = it.value === '' ? '-' : it.value
      return `Update bay ${bayNumber} ${type}: ${value}`
    })
    .join("\n")

  const date = dayjs(updatedAtMs).format('DD MMM YYYY HH:mm a')
  toast((
    <div>
      <h4>{date}</h4>
      <p>{text}</p>
    </div>
  ), {
    pauseOnHover: true
  })
}

export default toastMessage