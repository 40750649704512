import React from 'react'
import MapView from '../../components/MapView/MapView'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import useHistoryMap from '../../hooks/useHistoryMap'
import dayjs from 'dayjs'
import SliderControl from '../../components/SliderControl/SliderControl'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase/app'
import { auth } from '../../state/FirebaseApp'
import './ReplayMap.scss'
import HorizontalTimeline from '../../components/HorizontalTimeline/HorizontalTimeline'
import { LogyardHistoryMap } from '../../types'
import StepControl from './StepControl'
import Modal from '../../components/Modal/Modal'
import useMapName from '../../hooks/useMapName'
import ErrorBox from '../../components/ErrorBox'
import { currentMapStateRef, useMapTemplateInfo } from '../../hooks/useCurrentMap'
import { RootContext } from '../../state/RootContext'
import { diff as justDiff } from 'just-diff'
import { toast } from 'react-toastify'
import toastMessage from '../../utils/toastMessage'

// const getNearestMap = (timestampMs: number) => (prev: LogyardHistoryMap?, next: LogyardHistoryMap): LogyardHistoryMap => {
//   if (prev) {
//     const nextDiff = Math.abs(timestampMs - next.updated_at_ms)
//     const prevDiff = Math.abs(timestampMs - prev.updated_at_ms)

//     if (nextDiff < prevDiff) {
//       return next
//     }
//   }

//   return prev ?? next
// }

const getNearestIndex = (maps: LogyardHistoryMap[], value: number) => {
  let index = 0

  maps.reduce((prev, next, idx) => {
    if (prev) {
      const prevDiff = Math.abs(value - prev.updated_at_ms)
      const nextDiff = Math.abs(value - next.updated_at_ms)

      if (nextDiff < prevDiff) {
        index = idx
        return next
      }
    }

    return prev ?? next
  }, null as LogyardHistoryMap|null)

  return index
}

const ReplayMap = () => {
  const { state } = React.useContext(RootContext)
  const { selectedMap: selectedMapName } = state
  const [user] = useAuthState(auth) as [firebase.User, boolean, Error|undefined]
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | undefined>(user ? dayjs() : undefined)
  // const startOfDayMs = React.useMemo(() => selectedDate?.startOf('day').valueOf() ?? 0, [selectedDate])
  const endOfDayMs = React.useMemo(() => selectedDate?.endOf('day').valueOf() ?? 0, [selectedDate])
  const { maps: mapNames, fetching: fetchingMaps, error: errorMaps } = useMapName()
  const { info, fetching: fetchingInfo, error: errorInfo } = useMapTemplateInfo(selectedMapName)

  const [maps, fetching, error] = useHistoryMap(selectedDate, selectedMapName)
  const [sliderValue, setSliderValue] = React.useState(endOfDayMs)

  const onChangeDate = React.useCallback((e: React.FormEvent<HTMLSelectElement>) => {
    try {
      const date = dayjs(e.currentTarget.value)
      
      if (!date.isValid()) {
        throw new Error('Selected invalid date')
      }

      setSelectedDate(date)
      setSliderValue(date.endOf('day').valueOf())
    } catch (parseError: any) {
      console.error(parseError.message)
    }
  }, [])

  const selectedIndex = React.useMemo(() => maps ? getNearestIndex(maps, sliderValue) : null, [maps, sliderValue])

  const selectedMap = React.useMemo((): LogyardHistoryMap|null => {
    if (maps && selectedIndex !== null) {
      return maps[selectedIndex]
    }

    return null
  }, [maps, selectedIndex])

  const onClickSnapshot = React.useCallback((snapshot: LogyardHistoryMap) => setSliderValue(snapshot.updated_at_ms), [])

  const onStep = React.useCallback((dir: number) => {
    if (selectedIndex !== null && maps) {
      const map = maps[selectedIndex + dir]

      if (map) {
        toastMessage(maps[selectedIndex], map)
        setSliderValue(map.updated_at_ms)
      }
    }
  }, [maps, selectedIndex])

  const onNext = React.useCallback(() => onStep(1), [onStep])
  const onPrev = React.useCallback(() => onStep(-1), [onStep])
  const [showRestore, setShowRestore] = React.useState(false)

  const onClickRestoreMap = React.useCallback(() => {
    if (selectedMap) {
      const map = {...selectedMap.map}
      map.updatedAt = Date.now() / 1000
      currentMapStateRef(map.name).set(map, { merge: true })
      setShowRestore(false)
    }
  }, [selectedMap])

  const dates = React.useMemo(() => {
    return [0,1,2,3,4,5,6,7].map(offset => dayjs().subtract(offset, 'day'))
  }, [])

  if (!user) {
    return null
  }

  if (fetchingMaps) {
    return <ProgressBar />
  }

  if (errorMaps) {
    return <ErrorBox error={errorMaps}/>
  }

  if (fetchingInfo) {
    return <ProgressBar />
  }

  if (errorInfo) {
    return <ErrorBox error={errorInfo}/>
  }
  
  if (!info) {
    return null
  }

  return (
    <div className="ReplayMap">
      <div className="ActionRow">
        <label className="DropDown">
          <span>Select Date</span>
          <select onChange={onChangeDate} value={selectedDate?.format('MMMM D, YYYY')}>
            <option>[Please select]</option>
            {dates.map(it => <option key={it.valueOf()} value={it.format('MMMM D, YYYY')}>{it.format('MMMM D, YYYY')}</option>)}
          </select>
        </label>
        {selectedMap && <button className="button" onClick={() => setShowRestore(true)}>Replace with this map</button>}
        {showRestore && (
          <Modal className="Confirmation" onClose={() => setShowRestore(false)}>
            <span>Are you sure you want to restore to this map?</span>
            <div>
              <button className="button" onClick={onClickRestoreMap}>Yes</button>
              <button className="button danger" onClick={() => setShowRestore(false)}>Cancel</button>
            </div>
          </Modal>
        )}
      </div>
      
      {fetching && <ProgressBar/>}

      <div className="GeneralInfo">
        <span className="SelectedUpdate">{selectedIndex !== null ? selectedIndex + 1 + ' /' : null} {maps?.length}</span>
        <span className="MapName">{selectedMap?.map_name.toLowerCase().replace('testmap', ' ')}</span>
        <span className="SelectedTime">{selectedMap && dayjs(selectedMap.updated_at_ms).format('MMMM D, YYYY HH:mm:ss')}</span>
      </div>

      {selectedMap && maps && maps.length > 0 && (
        <>
          <div className="SliderContainer">
            <StepControl onClick={onPrev} direction="prev"/>

            <div className="Slider">
              <SliderControl min={maps[0].updated_at_ms} max={maps[maps.length - 1].updated_at_ms} step={1} value={sliderValue} onChange={e => setSliderValue(parseFloat(e.target.value))}/>
              <HorizontalTimeline minMs={maps[0].updated_at_ms} maxMs={maps[maps.length - 1].updated_at_ms} selectedMap={selectedMap} items={maps ?? []} onClick={onClickSnapshot}/>
            </div>
            
            <StepControl onClick={onNext} direction="next"/>
          </div>

          {error && <span className="Error">{error.message}</span>}
          <MapView map={selectedMap.map} mapInfo={info} key="mapview"/>
        </>
      )}

      {!(selectedMap && maps && maps.length > 0) && <div className="PlaceholderMap"></div>}
    </div>
  )
}

export default ReplayMap