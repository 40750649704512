import React from 'react'
import { LogyardMap } from '../types'
import { auth, db } from '../state/FirebaseApp'
import { MapInfo } from './useMaps'
import { diff as justDiff, Operation } from 'just-diff'

// Map template and background map image references.
export const templateMapRef = (mapName: string) => db.collection('maps').doc(mapName)

// Live version of the map. This is the map that the view is rendered from.
export const currentMapStateRef = (mapName: string) => db.collection('currentMap').doc(mapName)

export const useCurrentMapState = (mapName: string|undefined) => {
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const [mapState, setMapState] = React.useState<LogyardMap>()

  React.useEffect(() => {
    if (mapName && auth.currentUser) {
      setFetching(true)
      setError(undefined)

      currentMapStateRef(mapName)
        .get()
        .then(doc => setMapState(doc.data() as LogyardMap))
        .catch(err => setError(err))
        .finally(() => setFetching(false))
    }
  }, [mapName])

  return {
    mapState,
    fetching,
    error,
  }
}

export const useMapTemplateInfo = (mapName: string|undefined) => {
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const [info, setInfo] = React.useState<MapInfo>()

  React.useEffect(() => {
    const load = async () => {
      try {
        setFetching(true)
        setError(undefined)
  
        if (mapName && auth.currentUser) {
          const snap = await templateMapRef(mapName).get()
          setInfo({
            uid: snap.id,
            ...(snap.data() as any),
          })
        }
      } catch (err: any) {
        setError(err)
      } finally {
        setFetching(false)
      }
    }

    load()
  }, [mapName])

  return {
    info,
    fetching,
    error,
  }
}

export const useCurrentMapListener = (mapName: string|undefined) => {
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const [data, setData] = React.useState<LogyardMap>()
  const [diff, setDiff] = React.useState<{
    op: Operation;
    path: (string | number)[];
    value: any;
  }[]>()

  React.useEffect(() => {
    if (mapName && auth.currentUser) {
      setFetching(true)
      setError(undefined)
      setData(undefined)

      return currentMapStateRef(mapName)
        .onSnapshot(doc => {
          setData(prev => {
            const next = doc.data() as LogyardMap

            if (prev && next) {
              setDiff(justDiff(prev, next))
            }
            
            return next
          })
          setFetching(false)
        }, err => {
          setFetching(false)
          setError(err)
        })
    }
  }, [mapName])

  return {
    map: data, 
    fetching, 
    error,
    diff,
  }
}