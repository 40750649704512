import * as Math from 'mathjs'
import { Matrix } from 'mathjs'

export const calculateHomography = (a1: number[], b1: number[], a2: number[], b2: number[], a3: number[], b3: number[], a4: number[],  b4: number[]): Matrix => {    
  const points = Math.matrix([
    [-a1[0] , -a1[1] , -1   , 0     , 0     , 0     , a1[0]*b1[0]   , a1[1]*b1[0]   , b1[0]],
    [0      , 0      , 0    , -a1[0], -a1[1], -1    , a1[0]*b1[1]   , a1[1]*b1[1]   , b1[1]],
    [-a2[0] , -a2[1] , -1   , 0     , 0     , 0     , a2[0]*b2[0]   , a2[1]*b2[0]   , b2[0]],
    [0      , 0      , 0    , -a2[0], -a2[1], -1    , a2[0]*b2[1]   , a2[1]*b2[1]   , b2[1]],
    [-a3[0] , -a3[1] , -1   , 0     , 0     , 0     , a3[0]*b3[0]   , a3[1]*b3[0]   , b3[0]],
    [0      , 0      , 0    , -a3[0], -a3[1], -1    , a3[0]*b3[1]   , a3[1]*b3[1]   , b3[1]],
    [-a4[0] , -a4[1] , -1   , 0     , 0     , 0     , a4[0]*b4[0]   , a4[1]*b4[0]   , b4[0]],
    [0      , 0      , 0    , -a4[0], -a4[1], -1    , a4[0]*b4[1]   , a4[1]*b4[1]   , b4[1]],
    [0      , 0      , 0    , 0     , 0     , 0     , 0             , 0             , 1     ]
  ])

  const pointsInv = Math.inv(points) as Matrix
  const hnum = (Math.multiply(pointsInv, [0,0,0,0,0,0,0,0,1])) as Matrix

  return Math.reshape(hnum, [3,3]) as Matrix
}

export const makeTransformer = (
  a1: number[],
  a2: number[],
  a3: number[],
  a4: number[],
  b1: number[],
  b2: number[],
  b3: number[],
  b4: number[],
) => {
  const h = calculateHomography(a1, b1, a2, b2, a3, b3, a4, b4)
  return (lat: number, lng: number) => {
    const b = Math.multiply(h, [lat, lng, 1]).toArray() as number[]
    b[0] = b[0] / b[2]
    b[1] = b[1] / b[2]
    
    return {
      x: b[0],
      y: b[1],
    }
  }
}