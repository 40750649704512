import React from 'react'
import useLocalStorage from '../../hooks/useLocalStorage'
import Logo from '../../logo.png'
import './Login.scss'
import { auth } from '../../state/FirebaseApp'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase/app'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom'
import { Alert } from '@mui/material'

const theme = createTheme();

const Login = () => {
  const [, userLoading, authError] = useAuthState(auth) as [firebase.User, boolean, Error|undefined]
  const [email, setEmail] = useLocalStorage('email', '')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState<Error>()
  const [loading, setLoading] = React.useState(false)

  const isLoading = userLoading || loading

  const handleSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {
      setError(undefined)
      await auth.signInWithEmailAndPassword(email, password)
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [email, password])

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: 2, mb: 2 }}>
            <img src={Logo} width="150" height="auto" alt="Logyard"/>
          </Box>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              disabled={isLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              Sign In
            </Button>
            {error && <Alert severity="error">{error.message}</Alert>}
            {authError && <Alert severity="error">{authError.message}</Alert>}
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Login