import React from 'react'
import { VscChevronLeft, VscChevronRight } from "react-icons/vsc"
import './StepControl.scss'

type StepControlProps = {
  onClick: () => void
  direction: 'next'|'prev'
}

const StepControl: React.FC<StepControlProps> = (props) => {
  const { onClick, direction } = props

  return (
    <div className="StepControl" onClick={onClick}>
      {direction === 'next' && <VscChevronRight />}
      {direction === 'prev' && <VscChevronLeft />}
    </div>
  )
}

export default StepControl