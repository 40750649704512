import React from "react";
import PaperContainer from "../../components/PaperContainer/PaperContainer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../state/FirebaseApp";
import { Alert } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Settings() {
  const [user] = useAuthState(auth)
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState<Error>()
  const [dialogTitle, setDialogTitle] = React.useState('Change Password')
  const [dialogError, setDialogError] = React.useState<Error>()

  const handleSubmit = React.useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDialogError(undefined)

    if (password.length < 6) {
      setError(new Error('Password must be at least 6 characters'))
      return
    }

    setOpen(true)
  }, [password]);

  const [open, setOpen] = React.useState(false)
  const handleClose = React.useCallback(() => setOpen(false), [])
  const handleConfirmPasswordChange = React.useCallback(async () => {
    try {
      setDialogError(undefined)

      if (auth.currentUser) {
        setDialogTitle('Updating password...')
        await auth.currentUser?.updatePassword(password)
        handleClose()
      } else {
        throw new Error('No user logged in')
      }
    } catch (error: any) {
      setDialogError(error)
    } finally {
      setDialogTitle('Change Password')
    }
  }, [handleClose, password])

  return (
    <PaperContainer>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">Change Password</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled
            value={user?.email ?? ''}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && <Alert severity="error">{error.message}</Alert>}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Change Password</Button>
        </Box>
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to change your password?
          </DialogContentText>
        </DialogContent>
        {dialogError && (
          <DialogContent>
            <Alert severity="error">{dialogError?.message}</Alert>
          </DialogContent>
        )}
        <DialogActions>
          {!dialogError && <Button onClick={handleConfirmPasswordChange}>Yes</Button>}
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </PaperContainer>
  );
}

export default Settings;
