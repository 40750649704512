import React from "react"
import { realtime } from "../state/FirebaseApp"
import lodash from 'lodash'

type GrabberLocation = {
  coordinate: [number, number]
  email: string
  name: string
}

const useGrabberLocations = (mapName?: string) => {
  const [data, setData] = React.useState<GrabberLocation[]>([])

  React.useEffect(() => {
    if (mapName) {
      const ref = realtime.ref('tempPublicLocations')

      ref.on('value', snap => {
        snap.forEach(it => {
          setData(prev => {
            return lodash.uniqBy([
              it.val(),
              ...prev,
            ], 'email')
          })
        })
      })
  
      return () => ref.off()
    }
  }, [mapName])

  return {
    grabbers: data,
  }
}

export default useGrabberLocations