import React from 'react'
import styles from './ProgressBar.module.css'

const ProgressBar = () => {
  return (
    <div className={styles.ProgressBar}>
      <span className="Bar">
        <span className="Progress"></span>
      </span>
    </div>
  )
}

export default ProgressBar