import React from "react";
import { useQueryClient } from "react-query";
import { auth, functions } from "../../state/FirebaseApp";

export default function useLoginAsToken() {
  const queryClient = useQueryClient()

  return React.useCallback(async (email: string) => {
    functions.httpsCallable('fetchLoginToken')
    const callable = functions.httpsCallable('fetchLoginToken')
    const { data } = await callable({ email })
    const { token } = data
  
    if (typeof token === 'string') {
      await auth.signInWithCustomToken(token)
      queryClient.clear()
      window.location.href = '/'
    }
  }, [queryClient])
}