import React from 'react'
import './Modal.scss'

type ModalProps = {
  className?: string
  onClose?: () => void
}
const Modal: React.FC<ModalProps> = ({ className, onClose, children }) => {
  return (
    <div className="ModalBackground">
      <div className={`Modal ${className}`}>
        <div className="Close" onClick={onClose}>
          Close
        </div>
        <div className="ModalContent">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal