import React from 'react'
import { LogyardHistoryMap } from '../types'
import { auth, db } from '../state/FirebaseApp'
import dayjs from 'dayjs'
import lodash from 'lodash'

const historyMapRef = db.collection('history')

export const updateMap = (selectedMap: LogyardHistoryMap) => {
  if (auth.currentUser) {
    historyMapRef.doc(selectedMap.id).set(JSON.parse(JSON.stringify(selectedMap)), { merge: true })
  }
} 

const useHistoryMap = (day: dayjs.Dayjs|undefined, mapName: string|undefined): [LogyardHistoryMap[]|undefined, boolean, Error|undefined] => {
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState<Error>()
  const [data, setData] = React.useState<LogyardHistoryMap[]>()

  React.useEffect(() => {
    if (day && mapName && auth.currentUser) {
      setData(undefined)
      setFetching(true)
      setError(undefined)

      return historyMapRef
        .where('map_name', '==', mapName)
        .where('updated_at_ms', '>=', day.startOf('day').valueOf())
        .where('updated_at_ms', '<=', day.endOf('day').valueOf())
        .onSnapshot(snaps => {
          const results: LogyardHistoryMap[] = []
          snaps.forEach(snap => {
            results.push({
              id: snap.id,
              ...snap.data(),
            } as LogyardHistoryMap)  
          })

          setData(prev => {
            const arr = ([...(prev || []), ...results])
            const uniq = lodash.uniqBy(arr, 'id')
            return uniq.sort((a, b) => a.updated_at_ms - b.updated_at_ms)
          })

          setFetching(false)
        }, error => {
          setFetching(false)
          setError(error)
        })
    }
  }, [day, mapName])

  return [data, fetching, error]
}

export default useHistoryMap