import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'

firebase.initializeApp({
  apiKey: "AIzaSyCfhVIoPvo1S4NWyLCXLUnwWRjj5DCt-L4",
  authDomain: "logyard-app.firebaseapp.com",
  databaseURL: "https://logyard-app.firebaseio.com",
  projectId: "logyard-app",
  storageBucket: "logyard-app.appspot.com",
  messagingSenderId: "206047636089",
  appId: "1:206047636089:web:e93f93f440fbd0a94682a8"
})

export const auth = firebase.auth()
export const db = firebase.firestore()
export const realtime = firebase.database()
export const storage = firebase.storage()
export const functions = firebase.functions()