import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Alert, Grid, Link } from "@mui/material";
import PaperContainer from "../components/PaperContainer/PaperContainer";
import { auth } from "../state/FirebaseApp";
import isEmail from "../utils/isEmail";
import { Link as RouterLink } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState<Error>()
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState<string|undefined>()

  const handleSubmit = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined)
    setLoading(true)
    setMessage(undefined)

    try {
      if (isEmail(email)) {
        await auth.sendPasswordResetEmail(email)
        setMessage('Password reset email sent')
      } else {
        throw new Error('Invalid email')
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [email]);

  return (
    <PaperContainer>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">Forgot Password</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />

          {error && <Alert severity="error">{error.message}</Alert>}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>Send reset link</Button>
          {message && <Alert>{message}</Alert>}
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/" variant="body2">
                  Back
                </Link>
              </Grid>
            </Grid>
        </Box>
      </Box>
    </PaperContainer>
  );
}

export default ForgotPassword;
