import PaperContainer from "../PaperContainer/PaperContainer"
import UsersTable from "./UsersTable"

function UserManagement() {
  return (
    <PaperContainer>
      <UsersTable />
    </PaperContainer>
  )
}

export default UserManagement