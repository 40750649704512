import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import useUsers from './useUsers';
import Button from '@mui/material/Button';
import useLoginAsToken from './useLoginAsToken'

// Generate Order Data
function createData(
  id: string,
  name: string,
  email: string,
) {
  return { id, name, email };
}

export default function UsersTable() {
  const { data: users } = useUsers()
  const loginAs = useLoginAsToken()

  const rows = React.useMemo(() => {
    return (users ?? []).map((user) => {
      return createData(
        user.id,
        user.name,
        user.email,
      )
    })
  }, [users])

  return (
    <React.Fragment>
      <Title>Users</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>ID</TableCell>
            <TableCell align="right">Login as</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell align="right">
                <Button variant="outlined" size="small" onClick={() => loginAs(row.email)}>Login as</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
