import React from 'react'
import ErrorBox from '../../components/ErrorBox'
import MapView from '../../components/MapView/MapView'
import ProgressBar from '../../components/ProgressBar/ProgressBar'
import { useMapTemplateInfo, useCurrentMapListener } from '../../hooks/useCurrentMap'
import { RootContext } from '../../state/RootContext'
import { toastMessageFromDiff } from '../../utils/toastMessage'

const Live = () => {
  const { state } = React.useContext(RootContext)
  const { selectedMap } = state
  const { info, fetching: fetchingInfo, error: errorInfo } = useMapTemplateInfo(selectedMap)
  const { map, fetching, error, diff } = useCurrentMapListener(selectedMap)

  React.useEffect(() => {
    if (diff) {
      toastMessageFromDiff(Date.now(), diff)
    }
  }, [diff])

  if (map && selectedMap && info) {
    return <MapView map={map} mapInfo={info} showGrabbers/>
  }

  if (error) {
    return <ErrorBox error={error}/>
  }

  if (errorInfo) {
    return <ErrorBox error={errorInfo}/>
  }

  if (fetching) {
    return <ProgressBar />
  }

  if (fetchingInfo) {
    return <ProgressBar />
  }
  
  return null
}

export default Live